// *PACKAGES* //
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
// *FUNCTIONS* //
import App from './App';
import { Register } from './serviceWorkerRegistration';
// *STYLES* //
import './reset.css';
import './fonts.css';

// Create root
const root = ReactDOM.createRoot(document.getElementById('root'));
// Render root
root.render(
  <React.StrictMode>
    <Router>
      <App/>
    </Router>
  </React.StrictMode>
);

// Register service worker
Register();