// *PACKAGES* //
import React, { useState } from "react";
import bcrypt from "bcryptjs";
// *FUNCTIONS* //
import PostData from "./PostData.js";
import VerifyPage from "./VerifyPage.js";
// *STYLES* //
import "./style/Log.css";
import "./style/mobile.css";

// *PASSWORD RESET PAGE FUNCTION* //
export default function PasswordResetPage({ PasswordResetBack }) {
    // Email react hooks
    const [Email, SetEmail] = useState("");
    // New password react hooks
    const [NewPassword, SetNewPassword] = useState("");
    const [ShowNewPassword, SetShowNewPassword] = useState(false);
    // Confirm new password react hooks
    const [ConfirmNewPassword, SetConfirmNewPassword] = useState("");
    const [ShowConfirmNewPassword, SetShowConfirmPassword] = useState(false);
    // Verify form react hooks
    const [VerifyForm, SetVerifyForm] = useState(false);
    // Notification react hooks
    const [Notification, SetNotification] = useState(null);
    // Loading react hooks
    const [Loading, SetLoading] = useState(false);

    // *REGISTER HANDLE FUNCTION* //
    function PasswordReset(data) {
        // Prevent default browser actions
        data.preventDefault();

        // Set loading
        SetLoading(true);

        // Password and email rules
        const password_rules = new RegExp("^(?=.*[A-Z])(?=.*\\d)(?=.*[\\W_])[A-Za-z\\d\\W_]{8,35}$");
        const email_rules = new RegExp("^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6}$");

        // Set notification
        SetNotification("");

        // If the fields are not filled in
        if (Email.trim() === "" && NewPassword.trim() === "" && ConfirmNewPassword.trim() === "") {
            SetNotification('Pole musí být vyplněna.'); // Set notification
            // Set loading
            SetLoading(false);
        }
        // If the email rules test not go well
        else if ( !email_rules.test(Email) ) {
            SetNotification("Nesprávný formát emailu."); // Set notification
            // Set loading
            SetLoading(false);
        }
        // If the new password rules test not go well
        else if ( !password_rules.test(NewPassword) ) {
            SetNotification("Heslo musí obsahovat alespoň jedno velké písmeno, jeden speciální znak, jednu číslici a musí mít alespoň 8 znaků."); // Set notification
            // Set loading
            SetLoading(false);
        }
        // If password from confirm password is not match
        else if ( !(NewPassword === ConfirmNewPassword) ) {
            SetNotification("Hesla se neshodují."); // Set notification
            // Set loading
            SetLoading(false);
        }
        // If all is true
        else {
            // Hash password
            bcrypt.hash(NewPassword, 10, function (err, hashed_password) {
                // If err not null
                if (err) {
                    console.error("Password hashing failed:", err); // print error into console
                    SetNotification("Registrace selhala. Prosím zkus to znovu."); // set notification
                    // Set loading
                    SetLoading(false);
                }

                // Post data [PostData.js]
                PostData("password_reset.php", {email:Email,new_password:hashed_password})
                // Wait for response
                .then((response) => {
                    // Convert response into json object
                    let response_json = JSON.parse(response);

                    // If response not undefined
                    if (response_json.message !== undefined || response_json.successful !== undefined) {
                        // Set notification
                        SetNotification(response_json.message);
                        // Save email into local storage
                        localStorage.setItem('email', Email);

                        // If response successful is true
                        if (response_json.successful) {
                            // Call function after 2 seconds
                            setTimeout(() => {
                                // Set loading
                                SetLoading(false);
                                // Go to verify form
                                SetVerifyForm(true);
                            }, 2000);
                        } else {
                            // Set loading
                            SetLoading(false);
                        }
                    } else {
                        console.error("Registration failed:", response); // Print error into console
                        SetNotification("Registrace selhala. Prosím zkus to znovu."); // Set notification
                        // Set loading
                        SetLoading(false);
                    }
                })
                // Catch error
                .catch((error) => {
                    console.error("Registration failed:", error); // Print error into console
                    SetNotification("Registrace selhala. Prosím zkus to znovu."); // Set notification
                    // Set loading
                    SetLoading(false);
                });
            });
        }
    }

    // *RETURN JSX OBJECT* //
    return (
        <>
            {/* If verify form is true */}
            {VerifyForm ? (
                <VerifyPage VerifyScript="password_reset_verify.php"  VerifySuccess={() => setTimeout(() => PasswordResetBack(false), 2000)} VerifyBack = {SetVerifyForm} PasswordResetEmail = {true}/>

            ) : (
                <form className="formlog"> {/* Form for password reset */}
                    {/* Form heading */}
                    <h1 className="h1log">Obnovení hesla</h1>

                    {/* Email input */}
                    <input
                        className="inputlog"
                        type="email"
                        name="email"
                        maxLength="50"
                        value={Email}
                        placeholder="Email"
                        onChange={(data) => SetEmail(data.target.value)}
                    />

                    {/* New password container */}
                    <div style={{ position: "relative" }}>
                        {/* Password input */}
                        <input
                            className="inputlog"
                            type={ShowNewPassword ? "text" : "password"}
                            name="newPassword"
                            placeholder="Nové heslo"
                            maxLength="36"
                            value={NewPassword}
                            onChange={(data) => SetNewPassword(data.target.value)}
                        />
                        {/* Show/Hide password button */}
                        <button
                            className="buttonshow"
                            type="button"
                            style={{
                                position: "absolute",
                                right: "0px",
                                top: "-10px",
                                minWidth: "80px",
                                maxWidth: "80px",
                                borderRadius: "0px 50px 50px 0px",
                                backgroundColor: "transparent",
                                border: "none",
                                cursor: "pointer",
                            }}
                            onClick={() => SetShowNewPassword(!ShowNewPassword)}
                        >{ShowNewPassword ? "Skrýt" : "Zobrazit"}</button>
                    </div>

                    {/* Confirm new password container */}
                    <div style={{ position: "relative" }}>
                        {/* Confirm password input */}
                        <input
                            className="inputlog"
                            type={ShowConfirmNewPassword ? "text" : "password"}
                            name="confirmNewPassword"
                            placeholder="Nové heslo znovu"
                            maxLength="36"
                            value={ConfirmNewPassword}
                            onChange={(data) => SetConfirmNewPassword(data.target.value)}
                        />
                        {/* Show/Hide confirm password button */}
                        <button
                            className="buttonshow"
                            type="button"
                            style={{
                                position: "absolute",
                                right: "0px",
                                top: "-10px",
                                minWidth: "80px",
                                maxWidth: "80px",
                                borderRadius: "0px 50px 50px 0px",
                                backgroundColor: "transparent",
                                border: "none",
                                cursor: "pointer",
                            }}
                            onClick={() => SetShowConfirmPassword(!ShowConfirmNewPassword)}
                        >{ShowConfirmNewPassword ? "Skrýt" : "Zobrazit"}</button>
                    </div>

                    {/* Go back button */}
                    <button
                        className="buttonshow"
                        onClick={() => PasswordResetBack(false)}
                    >Zpět</button>

                    {/* Password reset button */}
                    <button
                        onClick={PasswordReset}
                        disabled={Loading}
                        className="buttonlog_loading4"
                    >{Loading ? <div className="spinner"></div> : 'Obnovit heslo'}</button>

                    {/* Notification */}
                    {Notification && <p className="plog">{Notification}</p>}
                </form>
            )}
        </>
    );
}