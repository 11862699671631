// *PACKAGES* //
import React, { useState } from "react";
import bcrypt from "bcryptjs";
// *FUNCTIONS* //
import PostData from "./PostData.js";
import VerifyPage from "./VerifyPage.js";
// *STYLES* //
import "./style/Log.css";
import "./style/mobile.css";

// *REGISTER PAGE FUNCTION* //
export default function RegisterPage({ RegisterSuccess }) {
  // Email react hooks
  const [Email, SetEmail] = useState("");
  // Password react hooks
  const [Password, SetPassword] = useState("");
  const [ShowPassword, SetShowPassword] = useState(false);
  // Confirm password react hooks
  const [ConfirmPassword, SetConfirmPassword] = useState("");
  const [ShowConfirmPassword, SetShowConfirmPassword] = useState(false);
  // Verify form react hooks
  const [VerifyForm, SetVerifyForm] = useState(false);
  // Notification react hooks
  const [Notification, SetNotification] = useState(null);
  // Loading react hooks
  const [Loading, SetLoading] = useState(false);

  // *REGISTER HANDLE FUNCTION* //
  function RegisterHandle(data) {
    // Prevent default browser actions
    data.preventDefault();

    // Set loading
    SetLoading(true);

    // Password and email rules
    const password_rules = new RegExp("^(?=.*[A-Z])(?=.*\\d)(?=.*[\\W_])[A-Za-z\\d\\W_]{8,35}$");
    const email_rules = new RegExp("^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6}$");

    // Set notification
    SetNotification("");

    // If the fields are not filled in
    if (Email.trim() === "" && Password.trim() === "" && ConfirmPassword.trim() === "") {
      SetNotification('Pole musí být vyplněna.'); // Set notification
      // Set loading
      SetLoading(false);
    }
    // If the email rules test go well
    else if ( !email_rules.test(Email) ) {
      SetNotification("Nesprávný formát emailu."); // Set notification
      // Set loading
      SetLoading(false);
    }
    // If the password rules test go well
    else if ( !password_rules.test(Password) ) {
      SetNotification("Heslo musí obsahovat alespoň jedno velké písmeno, jeden speciální znak, jednu číslici a musí mít alespoň 8 znaků."); // Set notification
      // Set loading
      SetLoading(false);
    }
    // If password from confirm password is match
    else if ( !(Password === ConfirmPassword) ) {
      SetNotification("Hesla se neshodují."); // Set notification
      // Set loading
      SetLoading(false);
    }
    // If all is true
    else {
      // Hash password
      bcrypt.hash(Password, 10, function (err, hashed_password) {
        // If err not null
        if (err) {
          console.error("Password hashing failed:", err); // print error into console
          SetNotification("Registrace selhala. Prosím zkus to znovu."); // set notification
          // Set loading
          SetLoading(false);
        }

        // Post data [PostData.js]
        PostData("register.php", {email:Email,password:hashed_password})
        // Wait for response
        .then((response) => {
          // Convert response into json object
          let response_json = JSON.parse(response);

          // If response not undefined
          if (response_json.message !== undefined || response_json.successful !== undefined) {
            // Set notification
            SetNotification(response_json.message);

            // If response successful is true
            if (response_json.successful) {
              // Call function after 2 seconds
              setTimeout(() => {
                // Set loading
                SetLoading(false);
                // Save email into local storage
                localStorage.setItem('email', Email);
                // Write email and password into cookie
                const cookie_max_age = new Date(3000, 0, 1);
                document.cookie = `email=${Email}; expires=${cookie_max_age}; path=/; SameSite=Lax; Secure`;
                document.cookie = `password=${Password}; expires=${cookie_max_age}; path=/; SameSite=Lax; Secure`;
                // Go to verify form
                SetVerifyForm(true);
              }, 2000);
            } else {
              // Set loading
              SetLoading(false);
            }
          } else {
            console.error("Registration failed:", response); // Print error into console
            SetNotification("Registrace selhala. Prosím zkus to znovu."); // Set notification
            // Set loading
            SetLoading(false);
          }
        })
        // Catch error
        .catch((error) => {
          console.error("Registration failed:", error); // Print error into console
          SetNotification("Registrace selhala. Prosím zkus to znovu."); // Set notification
          // Set loading
          SetLoading(false);
        });
      });
    }
  }

  // *RETURN JSX OBJECT* //
  return (
    <>
      {/* If verify form is true */}
      {VerifyForm ? (
        <VerifyPage VerifyScript="register_verify.php"  VerifySuccess={() => setTimeout(() => RegisterSuccess(false), 2000)} VerifyBack = {SetVerifyForm}/>

      ) : (
        <form className="formlog"> {/* Form for register */}
          {/* Form heading */}
          <h1 className="h1log">Registrace</h1>

          {/* Email input */}
          <input
            className="inputlog"
            type="email"
            name="email"
            maxLength="50"
            value={Email}
            placeholder="Email"
            onChange={(data) => SetEmail(data.target.value)}
          />

          {/* Password container */}
          <div style={{ position: "relative" }}>
            {/* Password input */}
            <input
              className="inputlog"
              type={ShowPassword ? "text" : "password"}
              name="password"
              placeholder="Heslo"
              maxLength="36"
              value={Password}
              onChange={(data) => SetPassword(data.target.value)}
            />
            {/* Show/Hide password button */}
            <button
              className="buttonshow"
              type="button"
              style={{
                position: "absolute",
                right: "0px",
                top: "-10px",
                minWidth: "80px",
                maxWidth: "80px",
                borderRadius: "0px 50px 50px 0px",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => SetShowPassword(!ShowPassword)}
            >{ShowPassword ? "Skrýt" : "Zobrazit"}</button>
          </div>

          {/* Confirm password container */}
          <div style={{ position: "relative" }}>
            {/* Confirm password input */}
            <input
              className="inputlog"
              type={ShowConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              placeholder="Potvrzení hesla"
              value={ConfirmPassword}
              onChange={(data) => SetConfirmPassword(data.target.value)}
            />
            {/* Show/Hide confirm password button */}
            <button
              className="buttonshow"
              type="button"
              style={{
                position: "absolute",
                right: "0px",
                top: "-10px",
                minWidth: "80px",
                maxWidth: "80px",
                borderRadius: "0px 50px 50px 0px",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => SetShowConfirmPassword(!ShowConfirmPassword)}
            >{ShowConfirmPassword ? "Skrýt" : "Zobrazit"}</button>
          </div>

          {/* Register button */}
          <button
            onClick={RegisterHandle}
            disabled={Loading}
            className="buttonlog_loading"
          >{Loading ? <div className="spinner"></div> : 'Registrace'}</button>

          {/* Notification */}
          {Notification && <p className="plog">{Notification}</p>}
        </form>
      )}
    </>
  );
}