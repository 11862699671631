// *PACKAGES* //
import React, { useState, useEffect, useRef } from "react";
// *FUNCTIONS* //
import PostData from "./PostData.js";
// *STYLES* //
import "./style/Log.css";
import "./style/mobile.css";

// *VERIFY PAGE FUNCTION* //
export default function VerifyPage({ VerifyScript, VerifySuccess, VerifyBack, PasswordResetEmail = false }) {
    // Verify code react hooks
    const [VerifyInputValues, SetVerifyInputValues] = useState(['', '', '', '', '', '']);
    // Create mutable ref object
    const VerifyInputRefs = useRef([]);
    // New verify code button react hooks
    const [NewVerifyCodeButton, SetNewVerifyCodeButton] = useState(false);
    const [NewVerifyCodeButtonCount, SetNewVerifyCodeButtonCount] = useState(0);
    const [NewVerifyCodeButtonCountdown, SetNewVerifyCodeButtonCountdown] = useState(60);
    // Notification react hooks
    const [Notification, SetNotification] = useState('Kód byl úspěšně zaslán na email.');
    // Loading react hooks
    const [Loading, SetLoading] = useState(false);
    // New verify code loading react hooks
    const [NewCodeLoading, SetNewCodeLoading] = useState(false);

    // *NEW VERIFY CODE BUTTON SHOW* //
    useEffect(() => {
        if (!NewVerifyCodeButton && NewVerifyCodeButtonCount <= 3) {
            if (NewVerifyCodeButtonCountdown === 0) {
                SetNewVerifyCodeButton(true);
                SetNewVerifyCodeButtonCount(prevCount => prevCount + 1);
            } else if (NewVerifyCodeButtonCountdown > 0) {
                const timerId = setTimeout(() => SetNewVerifyCodeButtonCountdown(prevCountdown => prevCountdown - 1), 1000);
                return () => clearTimeout(timerId);
            }
        }
    }, [NewVerifyCodeButton, NewVerifyCodeButtonCountdown, NewVerifyCodeButtonCount]);

    // *VERIFY HANDLE FUNCTION* //
    function VerifyHandle(data) {
        // If data is not null
        if (data !== null) {
            // Prevent default browser actions
            data.preventDefault();
        }

        // Set loading
        SetLoading(true);

        // Verify code rules
        const verify_code_rules = new RegExp("^[0-9]{6}$");
        // Set notification
        SetNotification("");
        // Convert array to string
        const VerifyInputValuesString = VerifyInputValues.join('');

        // If the field are not filled in
        if (VerifyInputValuesString.trim() === "") {
            // Set notification
            SetNotification('Pole musí být vyplněna.');
            // Set loading
            SetLoading(false);
        }
        // If the verify code rules test go well
        else if ( !verify_code_rules.test(VerifyInputValuesString) ) {
            // Set notification
            SetNotification("Neplatný ověřovací kód.");
            // Set loading
            SetLoading(false);
        }
        // If all is true
        else {
            // Post data [PostData.js]
            PostData(VerifyScript, {verify_code: VerifyInputValuesString})
            // Wait for response
            .then((response) => {
                // Convert response into json object
                let response_json = JSON.parse(response);

                // If response not undefined
                if (response_json.message !== undefined || response_json.successful !== undefined) {
                    // Set notification
                    SetNotification(response_json.message);

                    // If response successful is true
                    if (response_json.successful) {
                        // If auth token not undefined
                        if (response_json.auth_token !== undefined) {
                            // Write auth token into cookie
                            document.cookie = `auth_token=${response_json.auth_token}; expires=${new Date(3000, 0, 1)}; path=/; SameSite=Lax; Secure`;
                        }

                        // Remove email from local storage
                        localStorage.removeItem('email');

                        // Call function after 2 seconds
                        setTimeout(() => {
                            // Set loading
                            SetLoading(false);
                            // Go back
                            VerifySuccess();
                        }, 2000);
                    } else {
                        // Set loading
                        SetLoading(false);
                    }
                } else {
                    console.error("Verify failed:", response); // Print error into console
                    SetNotification("Ověření selhalo. Prosím zkus to znovu."); // Set notification
                    // Set loading
                    SetLoading(false);
                }
            })
            // Catch error
            .catch((error) => {
                console.error("Verify failed:", error); // Print error into console
                SetNotification("Ověření selhalo. Prosím zkus to znovu."); // Set notification
                // Set loading
                SetLoading(false);
            });
        }
    }

    // *SEND NEW VERIFY CODE FUNCTION* //
    function SendNewVerifyCode(data) {
        // Prevent default browser actions
        data.preventDefault();

        // Set loading
        SetNewCodeLoading(true);

        // Post data [PostData.js]
        PostData("new_verify_code.php", {email: localStorage.getItem('email'), password_reset_email: PasswordResetEmail})
        // Wait for response
        .then((response) => {
            // Convert response into json object
            let response_json = JSON.parse(response);

            // If response not undefined
            if (response_json.message !== undefined || response_json.successful !== undefined) {
                // Set notification
                SetNotification(response_json.message);

                // If response successful is true
                if (response_json.successful) {
                    // Set SetNewCodeLoading
                    SetNewCodeLoading(false);
                    // Set SetNewVerifyCodeButton
                    SetNewVerifyCodeButton(false);
                    // Set SetNewVerifyCodeButtonCountdown on 60 seconds
                    SetNewVerifyCodeButtonCountdown(60);
                    // Set SetNotification
                    SetNotification('Ověřovací kód ti byl zaslán na email.')
                } else {
                    // Set loading
                    SetNewCodeLoading(false);
                }
            } else {
                console.error("Send new code failed:", response); // Print error into console
                SetNotification("Chyba, zkus to prosím znovu."); // Set notification
                // Remove cookies
                document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Lax; Secure`;";
                document.cookie = "password=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Lax; Secure`;";
                // Set loading
                SetNewCodeLoading(false);
            }
        })
        // Catch error
        .catch((error) => {
            console.error("Send new code:", error); // Print error into console
            SetNotification("Chyba, zkus to prosím znovu."); // Set notification
            // Set loading
            SetNewCodeLoading(false);
        });
    }

    // *VERIFY INPUTS HANDLE CHANGE* //
    const VerifyInputHandleChange = (e, index) => {
        const value = e.target.value;
        if (value.length <= 1) {
            const newValues = [...VerifyInputValues];
            newValues[index] = value;
            SetVerifyInputValues(newValues);
            if (value.length === 1 && index < 5) {
                VerifyInputRefs.current[index + 1].focus();
            }
        }
    };

    // *VERIFY INPUTS HANDLE KEY DOWN* //
    const VerifyInputHandleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && index > 0 && e.target.value === '') {
            VerifyInputRefs.current[index - 1].focus();
        }
    };

    // *RETURN JSX OBJECT* //
    return (
        <form className="formlog"> {/* Form for verify */}
            {/* Form heading */}
            <h1 className="h1log">Ověření</h1>

            {/* Verify inputs */}
            <div className="input-fields-container">
                <div className="input-fields">
                    {[0, 1, 2, 3, 4, 5].map((_, index) => (
                        <input
                            key={index}
                            type="tel"
                            maxLength="1"
                            value={VerifyInputValues[index]}
                            ref={el => VerifyInputRefs.current[index] = el}
                            onChange={e => VerifyInputHandleChange(e, index)}
                            onKeyDown={e => VerifyInputHandleKeyDown(e, index)}
                        />
                    ))}
                </div>
            </div>

            {/* Go back button */}
            <div className="button-container">
                <div className="button-row">
                    <button
                        className="buttonlog_loading3"
                        onClick={() => {
                            // Remove cookies
                            document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Lax; Secure`;";
                            document.cookie = "password=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Lax; Secure`;";
                            // Remove email from local storage
                            localStorage.removeItem('email');
                            // Go back
                            VerifyBack(false);
                        }}
                    >Zpět</button>

                    {/* Verify button */}
                    <button
                        onClick={VerifyHandle}
                        disabled={Loading}
                        className="buttonlog_loading3"
                    >{Loading ? <div className="spinner"></div> : 'Ověřit'}</button>
                </div>

                {/* If NewVerifyCodeButton is true, show send new verify button */}
                <button
                    onClick={SendNewVerifyCode}
                    disabled={NewCodeLoading || NewVerifyCodeButtonCountdown > 0 || NewVerifyCodeButtonCount > 3}
                    className="buttonlog_loading2"
                >{NewCodeLoading ? <div className="spinner"></div> : NewVerifyCodeButtonCountdown > 0 ? `Zaslat kód znovu (${NewVerifyCodeButtonCountdown})` : 'Zaslat kód znovu'}</button>
            </div>

            {/* Notification */}
            {Notification && <p className="plog">{Notification}</p>}
        </form>
    );
}