// TODO: DOCUMENTATION + OPTIMIZATION
// *PACKAGES* //
import React from "react";
import { useNavigate } from "react-router-dom";
// *ASSETS* //
import logo_transparent from "../../assets/logo_transparent.png";
import logo_instagram from "../../assets/instagram.png";
import logo_twitter from "../../assets/twitter.png";
import logo_tiktok from "../../assets/tiktok.png";
// *STYLES* //
import "../style/mobile.css";
import "../style/Footer.css";

export default function Footer() {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSocialClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <footer>
      <div className="footer_top">
      </div>
      <div className="line_bott" />
      <div className="footer_bottom">
      <section className="left_footer" onClick={handleLogoClick}>
          <img id="logofooter" src={logo_transparent} alt="Logo" />
          <h1 className="h1_footer">ByX</h1>
        </section>
        <div className="icons">
          <img src={logo_instagram} alt="instagram" onClick={() => handleSocialClick('https://www.instagram.com/byxbot')} />
          <img src={logo_twitter} alt="twitter" onClick={() => handleSocialClick('https://twitter.com/byxbot')} />
          <img src={logo_tiktok} alt="tiktok" onClick={() => handleSocialClick('https://www.tiktok.com/@byxbot')} />
        </div>
      </div>
    </footer>
  );
}