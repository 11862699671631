// *PACKAGES* //
import React, { useState, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
// *FUNCTIONS* //
import CheckBotStatus from "./components/CheckBotStatus";
import DataEntry from "./components/DataEntry";
import Filter from "./components/Filter";
import Header from "./components/Header";
import Footer from "./components/Footer";
// *ASSETS* //
import landing from "../assets/signals.png";
import winsvg from "../assets/win.svg";
import losssvg from "../assets/loss.svg";
import winratesvg from "../assets/winrate.svg";
// *STYLES* //
import "./style/In.css";
import "./style/mobile.css";
import "./style/loader.css";

// *APP FUNCTION* //
export default function App() {
  // TODO:
  const [items, setItems] = useState([]);
  // TODO:
  const [visibleItemsCount, setVisibleItemsCount] = useState(5);
  // TODO:
  const [searchQuery, setSearchQuery] = useState("");
  // TODO:
  const [filterStatus, setFilterStatus] = useState("all");
  // TODO:
  const [filterProfitLoss, setFilterProfitLoss] = useState("all");
  // TODO:
  const [showPopup, setShowPopup] = useState(true);
  // TODO:
  const [fadeOutPopup, setFadeOutPopup] = useState(false);
  // TODO:
  const [fadeOut, setFadeOut] = useState(false);
  // TODO:
  const [loadingMessage, setLoadingMessage] = useState("Loading your data");
  // TODO:
  const [showApplication, setShowApplication] = useState(false);
  // TODO:
  const [isNewSignal, setIsNewSignal] = useState(false);
  // TODO:
  const [winsCount, setWinsCount] = useState(0);
  // TODO:
  const [lossesCount, setLossesCount] = useState(0);
  // TODO:
  const [winRate, setWinRate] = useState(0);
  // TODO:
  const [, setIsLoading] = useState(true);
  // TODO:
  const [scrollEnabled, setScrollEnabled] = useState(false);
  // TODO:
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);

  const [filterTradeType, setFilterTradeType] = useState("all"); // Nov� stav pro typ obchod�

  // TODO:
  let wakeLock = null;
  // WebSocket init var
  let websocket = null;
  // Heartbeat interval var
  let heartbeat_interval = null;

  // TODO: ** //
  const registerServiceWorker = async () => {
    if ("serviceWorker" in navigator) {
      try {
        const registration = await navigator.serviceWorker.register("/service-worker.js");
        console.log("Service worker registered");

        if ('sync' in registration) {
          navigator.serviceWorker.ready.then((reg) => {
            return reg.sync.register('sync-new-signals');
          }).then(() => {
            console.log('Background Sync registered');
          }).catch((err) => {
            console.error('Background Sync registration failed', err);
          });
        } else {
          console.error('Background Sync not supported');
        }

      } catch (error) {
        console.error("Service worker registration failed", error);
      }
    }
  };

  // TODO: ** //
  const requestWakeLock = async () => {
    try {
      wakeLock = await navigator.wakeLock.request('screen');
      wakeLock.addEventListener('release', () => {
        console.log('Screen Wake Lock was released');
      });
      console.log('Screen Wake Lock is active');
    } catch (err) {
      console.error(`${err.name}, ${err.message}`);
    }
  };

  // TODO: ** //
  const requestNotificationPermission = () => {
    if (!("Notification" in window)) {
      alert("You have to save ByX App to homescreen to receive push-notifications.");
    } else if (Notification.permission === "granted") {
      setNotificationsEnabled(true);
      new Notification("You already have notifications enabled.");
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          setNotificationsEnabled(true);
          new Notification("Thank you for enabling notifications!");
          subscribeUserToPush();
        }
      });
    }
  };

  // TODO: ** //
  const copyToClipboard = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          console.log("Copied: ", text);
          showAlert("Zkopírováno");
        })
        .catch((err) => console.error("Error with Copy: ", err));
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      textArea.style.position = "fixed";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        const successful = document.execCommand("copy");
        const msg = successful ? "Zkopírováno" : "Chyba";
        console.log(msg);
        showAlert(msg);
      } catch (err) {
        console.error("Error: ", err);
      }
      document.body.removeChild(textArea);
    }
  };

  // TODO: ** //
  const showAlert = (message) => {
    const existingAlertBoxes = document.querySelectorAll(".alertBox");
    const alertBox = document.createElement("div");
    alertBox.classList.add("alertBox");
    alertBox.innerText = message;

    let topPosition = "10px";

    if (existingAlertBoxes.length > 0) {
      const lastAlertBox = existingAlertBoxes[existingAlertBoxes.length - 1];
      const lastAlertBoxBottom = lastAlertBox.offsetTop + lastAlertBox.offsetHeight;
      if (lastAlertBoxBottom + 10 < window.innerHeight) {
        topPosition = `${lastAlertBoxBottom + 10}px`;
      }
    }

    alertBox.style.top = topPosition;
    document.body.appendChild(alertBox);

    setTimeout(() => {
      alertBox.classList.add("hide");
      setTimeout(() => {
        alertBox.parentNode.removeChild(alertBox);
      }, 500);
    }, 2000);
  };

  // TODO: ** //
  const handleShowMore = () => {
    setVisibleItemsCount((prevCount) => prevCount + 10);
  };

  // TODO: ** //
  const closePopup = () => {
    setFadeOutPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 500);
  };

  // TODO: ** //
  const handleGoToApp = () => {
    // requestNotificationPermission(); - added soon
    setShowApplication(true);
    setTimeout(() => {
      document.getElementById("application").scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  // TODO: ** //
  const sendNotification = (message) => {
    if (notificationsEnabled) {
      navigator.serviceWorker.getRegistration().then((reg) => {
        reg.showNotification(message);
      });
    }
  };

  // TODO: ** //
  const urlBase64ToUint8Array = (base64String) => {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
    const rawData = window.atob(base64);
    return new Uint8Array(rawData.split("").map((char) => char.charCodeAt(0)));
  };

  // TODO: ** //
  const subscribeUserToPush = async () => {
    const registration = await navigator.serviceWorker.ready;
    const vapidPublicKey = "BMTf4W4tRauY7vWRtIDR4XWHFVkqe1oX7Sf7-I1h-J2JCO45y-APDYHVlxKm4zX_UhMRZrWlf9KjPqbjcMNi2pU";
    const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);

    try {
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertedVapidKey,
      });
      console.log("Chyba ověření uživatele:", subscription);
      await sendSubscriptionToServer(subscription);
    } catch (error) {
      console.error("Chyba ověření uživatele:", error);
    }
  };

  // TODO: ** //
  const sendSubscriptionToServer = async (subscription) => {
    try {
      const response = await fetch('/api/subscribe', {
        method: 'POST',
        body: JSON.stringify(subscription),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Bad status code from server.');
      }
      const responseData = await response.json();
      if (!(responseData && responseData.success)) {
        throw new Error('Bad response from server.');
      }
    } catch (error) {
      console.error('Chyba ověření serveru:', error);
    }
  };

  // TODO: ** //
  const filteredItems = items.filter((item) => {
    if (
      item.coin.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (filterStatus === "all" || item.type.toLowerCase().includes(filterStatus)) &&
      (filterProfitLoss === "all" ||
        (filterProfitLoss === "profit" &&
          (item.result === "Profit" ||
            item.result === "Closed Long" ||
            item.result === "Closed Short")) ||
        (filterProfitLoss === "loss" && item.result === "Loss")) &&
      (filterStatus === "opened" || filterTradeType === "all" ||
        (filterTradeType === "short" && item.type.toLowerCase().includes("short")) ||
        (filterTradeType === "long" && item.type.toLowerCase().includes("long")))
    ) {
      return true;
    }
    return false;
  });
  
  
  

  // TODO: ** //
  const coinOptions = useMemo(() => {
    if (!items) return [];
    const allCoins = items.map((item) => item.coin);
    return Array.from(new Set(allCoins));
  }, [items]);

  // TODO: ** //
  document.addEventListener('visibilitychange', () => {
    if (wakeLock !== null && document.visibilityState === 'visible') {
      requestWakeLock();
    }
  });

  // TODO: ** //
  useEffect(() => {
    if (Notification.permission === "granted") {
      setNotificationsEnabled(true);
    }
    registerServiceWorker();
    requestWakeLock();
  }, []);

  // TODO: ** //
  useEffect(() => {
    // Set body::before display to none when the component mounts
    document.body.style.setProperty("--before-display", "none");
    // Set body display to block when the component mounts
    document.body.style.setProperty("--body-display", "block");
  }, []);

  // TODO: ** //
  useEffect(() => {
    const popupTimer = setTimeout(() => {
      setFadeOutPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 500);
      setScrollEnabled(true);
    }, 5000);

    return () => clearTimeout(popupTimer);
  }, []);

  // TODO: ** //
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (items.length === 0) {
        setLoadingMessage("Nic nebylo nalezeno, omlouvám se.");
      }
    }, 15000);

    return () => clearTimeout(timeoutId);
  }, [items]);

  // TODO: ** //
  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeOut(true);
      setTimeout(() => setIsLoading(false), 2000);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  // *CONNECT WEBSOCKET FUNCTION* //
  function connect_web_socket() {
    // Create WebSocket
    websocket = new WebSocket(process.env.REACT_APP_WEBSOCKET);

    // Open handle
    websocket.onopen = () => {
      // Console log
      console.log('WebSocket connection established.');

      // Start heartbeat a ping websocket evry 5 seconds
      heartbeat_interval = setInterval(() => {
        // If ready state is websocket open
        if (websocket.readyState === WebSocket.OPEN) {
          // Send ping
          websocket.send(JSON.stringify({ type: 'ping' }));
        }
      }, 5000);
    };

    // Message handle
    websocket.onmessage = (event) => {
      // Get data from event
      const new_data = JSON.parse(event.data);

      // If type in new_data is pong
      if (new_data.type === 'pong') {
        // Return for ignore
        return;
      }

      // Set itmes for react station
      setItems((prev_items) => {
        // Filter existing ids
        const existing_ids = new Set(prev_items.map((item) => item.id));
        // Get new items where is not existing ids
        const new_items = new_data.filter((item) => !existing_ids.has(item.id));

        // If new items lenght is more then 0
        if (new_items.length > 0) {
          // Set is new signal
          setIsNewSignal(true);
        }

        // Retur array from new and previous items
        return [...new_items, ...prev_items];
      });
    };

    // Close handle
    websocket.onclose = (event) => {
      // Console log
      console.log('WebSocket connection closed.', event);

      // If heartbeat interval is not null
      if (heartbeat_interval) {
        // Clear interval
        clearInterval(heartbeat_interval);
        // Set heartbeat interval var on null
        heartbeat_interval = null;
      }

      // Reconnect after close an 15 seconds
      setTimeout(() => {
        // Console log
        console.log('Reconnecting websocket...');
        // Call connect web socket function
        connect_web_socket();
      }, 15000);
    };

    // Error handle
    websocket.onerror = (error) => {
      // Console error
      console.error('WebSocket error: ', error);
    };
  };

  // *INPUT WEBSOCKET* //
  useEffect(() => {
    // Call connect web socket function for init
    connect_web_socket();

    // Return function
    return () => {
      // If websocket is not null
      if (websocket) {
        // Console log
        console.log('Closing WebSocket...');
        // Close websocket
        websocket.close();
      }

      // If heartbeat interval is not null
      if (heartbeat_interval) {
        // Clear heartbeat interval
        clearInterval(heartbeat_interval);
      }
    };
  }, []);

  // TODO: ** //
  useEffect(() => {
    if (isNewSignal) {
      sendNotification("New Signal");
      setIsNewSignal(false);
    }
  }, [isNewSignal]);

  // TODO: ** //
  useEffect(() => {
    const handleScroll = (event) => {
      if (!scrollEnabled) {
        event.preventDefault();
        window.scrollTo(0, 0);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollEnabled]);

  // TODO: ** //
  useEffect(() => {
    let wins = 0;
    let losses = 0;
    items.forEach(item => {
      if (item.result === "Profit" || item.result === "Closed Long" || item.result === "Closed Short") {
        wins++;
      } else if (item.result === "Loss") {
        losses++;
      }
    });
    setWinsCount(wins);
    setLossesCount(losses);
    const currentWinRate = (wins / (wins + losses)) * 100;
    setWinRate(isNaN(currentWinRate) ? 0 : currentWinRate.toFixed(2));
  }, [items]);

  // *RETURN JSX OBJECT* //
  return (
    <>
      {showPopup && (
        <div className={`overlay ${fadeOutPopup ? "fade-out" : ""}`}>
          <div className={`popup ${fadeOutPopup ? "fade-out" : ""}`}>
            <h3 className="blue">
              Aplikace ByX - Beta2 v1.1.
            </h3>
            <h2 className="small">
              Další <strong> aktualizace </strong> již <strong> brzy</strong>!
            </h2>
          </div>
        </div>
      )}

      <div className={`loader-container ${fadeOut ? "fade-out" : ""}`}>
        <div className="loader"></div>
        <p className="beta-text">Apliace je v beta verzi, omluvte prosím chyby.</p>
      </div>
      <div>
        <section>
          <div className="sectionStyle"></div>
          <section className="upper_background">
            <Header />
            <CheckBotStatus />
            {showApplication ? null : (
              <section className="upper-text">
                <div>
                  <Helmet>
                    <link rel="preconnect" href="https://fonts.googleapis.com" />
                    <link
                      rel="preconnect"
                      href="https://fonts.gstatic.com"
                      crossorigin
                    />
                    <link
                      href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap"
                      rel="stylesheet"
                    />
                  </Helmet>
                </div>
                <section id="landingsection">
                  <img src={landing} alt="Signals" className="landing_text_img" />
                  <button className="appbutton" onClick={handleGoToApp}>Přejít do aplikace</button>
                </section>
              </section>
            )}
            <section id="application" style={{ display: showApplication ? "block" : "none" }}>
                <Filter
                coins={coinOptions}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                filterStatus={filterStatus}
                setFilterStatus={setFilterStatus}
                filterProfitLoss={filterProfitLoss}
                setFilterProfitLoss={setFilterProfitLoss}
                filterTradeType={filterTradeType} // P�id�n� nov�ho stavu do props
                setFilterTradeType={setFilterTradeType} // P�id�n� nov� setter funkce do props
              />

              <div className="stats">
                <span className="wins">
                  Wins: {winsCount}
                  <img src={winsvg} alt="check-circle" />
                </span>
                <span className="losses">
                  Losses: {lossesCount}
                  <img src={losssvg} alt="x-circle" />
                </span>
                <span className="win-rate">
                  Win Rate: {winRate}%
                  <img src={winratesvg} alt="bar-chart" />
                </span>
              </div>

              {filteredItems.length > 0 ? (
                filteredItems
                  .slice(0, visibleItemsCount)
                  .map((item, index) => (
                    <DataEntry
                      key={index}
                      item={item}
                      copyToClipboard={copyToClipboard}
                    />
                  ))
              ) : (
                loadingMessage && <div className="loading-animation">{loadingMessage}</div>
              )}
              {filteredItems.length > visibleItemsCount && (
                <button className="showMore" onClick={handleShowMore}>
                  Zobrazit více
                </button>
              )}
            </section>
            <Footer />
          </section>
        </section>
      </div>
    </>
  );
}