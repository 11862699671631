// TODO: DOCUMENTATION + OPTIMIZATION
// *PACKAGES* //
import React from "react";
// *ASSETS* //
import logo from "../../assets/logo_transparent.png";
// *STYLES* //
import "../style/Header.css";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggingOut: false, // Flag to track if logout process has started
    };
  }

  // *LOGOUT FUNCTION* //
  Logout = () => {
    // If logging out false
    if (!this.state.loggingOut) {
      // Remove cookies
      document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
      document.cookie = "password=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

      // Redirect to root page
      window.open(process.env.REACT_APP_DOMAIN, '_self');
    }
  };

  render() {
    return (
      <header>
        <div className="head">
          <div className="logo">
            <img src={logo} alt="logo" className="logo"/>
          </div>

          <button
            className="btn"
            onClick={this.Logout}
          >Odhlášení</button>
        </div>
      </header>
    );
  }
}

export default Header;