// *POST DATA FUNCTION* //
export default async function PostData(url = "", data = {}) {
  // Request send and await for response
  const response = await fetch((process.env.REACT_APP_PHP_PAGE+url), {
    method: "POST", // Http server send method
    headers: {"Content-Type": "application/json"}, // Send data in json formate
    body: JSON.stringify(data), // Convert data from json object into json string
  });

  // Wait and return response
  return await response.text();
}