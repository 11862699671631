// TODO: DOCUMENTATION + OPTIMIZATION
// *PACKAGES* //
import React, { useState, useEffect } from "react";

export default function CheckBotStatus() {
  const [botOnline, setBotOnline] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (botOnline === null) {
        setBotOnline(true); // Simulate bot online
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [botOnline]);

  return (
    <div id="statusContainer" className={botOnline ? "online" : "offline"}>
      <div className="dot" id="statusDot"></div>
      <div id="statusText">
        {botOnline === null
          ? "Načítání..."
          : botOnline
          ? "Bot Online"
          : "Bot Offline"}
      </div>
    </div>
  );
};