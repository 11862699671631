// *PACKAGES* //
import React, { useState, useEffect } from "react";
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
// *FUNCTIONS* //
import LoginPage from "./login/LoginPage.js";
import RegisterPage from "./login/RegisterPage.js";
import HomePage from "./coin/App.js";
// *ASSETS* //
import popup_logo from "./assets/logo_transparent.png";
import popup_gif_iphone from "./assets/gif.gif";
import popup_gif_android from "./assets/gif2.gif";
// *STYLES* //
import "./login/style/AppSearch.css";

// *DISABLE REACT DEV TOOLS* //
if (process.env.RUN_TYPE === 'production') {
  disableReactDevTools();
}

// *HOME SCREEN POPUP FUNCTION* //
const HomeScreenPopup = ({ onClose }) => {
  // Is home screen react hook
  const [IsHomeScreen, SetIsHomeScreen] = useState(false);

  // *STANDALONE MODE CHECK* //
  useEffect(() => {
    // If app is run in standalon mode, setIsHomeScreen on true
    SetIsHomeScreen(window.matchMedia("(display-mode: standalone)").matches || window.navigator.standalone);
  }, []);

  // *ADD APP TO HOME SCREEN FUNCTION* //
  function AddAppToHomeScreen() {
    // Create popup element
    const popup = document.createElement('div');
    popup.className = 'popupapp2 fadeout';

    // Create paragraph element
    const paragraph = document.createElement('p');
    paragraph.textContent = 'Pro plynulé používání si ulož prosím aplikaci ByX na domovskou obrazovku.';

    // Create gif element
    const gif = document.createElement('img');
    // If user agent include iPhone
    if (navigator.userAgent.includes('iPhone')) {
      gif.src = popup_gif_iphone;
    } else {
      gif.src = popup_gif_android;
    }
    gif.alt = 'gif';
    gif.className = 'gif';

    // Create close button element
    const close_button = document.createElement('button');
    close_button.textContent = 'Close';
    close_button.className = 'close-button';
    // Add click event listener for close button
    close_button.addEventListener('click', () => {
      // Hide popup, overlay and app
      popup.classList.add('fadeout-hidden');
      overlay.classList.add('fadeout-hidden');
      app.classList.add('fadeout-hidden');
      // After 500ms [animation time]
      setTimeout(() => {
        // Remove popup and overlay child
        document.body.removeChild(popup);
        document.body.removeChild(overlay);
        onClose(); // Clos all popups
      }, 500);
    });

    // Create overlay element
    const overlay = document.createElement('div');
    overlay.className = 'popupapp2-overlay fadeout';

    // Append paragraph as child for popup
    popup.appendChild(paragraph);
    // Append gif as child for popup
    popup.appendChild(gif);
    // Append gif as child for popup
    popup.appendChild(close_button);
    // Append popup as child for documend body
    document.body.appendChild(popup);
    // Append overlay as child for documend body
    document.body.appendChild(overlay);

    // Create query selector for .popupapp
    const app = document.querySelector('.popupapp');
  };

  // *RETURN JSX OBJECT* //
  return (
    <>
      {/* If home screen is not true */}
      {!IsHomeScreen && (
        <div className="popupapp fadeout"> {/* Popup app div */}
          {/* Popup logo */}
          <img src={popup_logo} alt="logo" className="logoapp" />
          {/* Div from paragraphs */}
          <div>
            <p className="loginappbig">ByX Trading</p>
            <p className="loginappsmall">Uložt si aplikaci pro plynujelší použití.</p>
          </div>
          {/*Button from add */}
          <button className="buttonapp" onClick={AddAppToHomeScreen}>Save</button>
        </div>
      )}
    </>
  );
};

// *APP FUNCTION* //
export default function App({ showRegister }) {
  // Loggin react hooks
  const [loggedIn, setLoggedIn] = useState(false);
  // Register react hooks
  const [isRegisterForm, setIsRegisterForm] = useState(showRegister);
  // Home screen popup react hooks
  const [showHomeScreenPopup, setShowHomeScreenPopup] = useState(true);

  // *RETURN JSX OBJECT* //
  return (
    <div className="App">
      {/* Home screen popup */}
      {showHomeScreenPopup && <HomeScreenPopup onClose={() => setShowHomeScreenPopup(false)} />}

      {/* If logged in is false */}
      {!loggedIn && (
        <>
          {/* If register form is true */}
          {isRegisterForm ? (
            <>
              {/* Register page [RegisterPage.js] render */}
              <RegisterPage RegisterSuccess={setIsRegisterForm} />
              {/* Button for switch to login form */}
              <button className="buttonlog2" onClick={() => setIsRegisterForm(false)}>Již máš účet?</button>
            </>
          ) : (
            <>
              {/* Login page [LoginPage.js] render */}
              <LoginPage LoggedIn={setLoggedIn} />
              {/* Button for switch to register form */}
              <button className="buttonlog2" onClick={() => setIsRegisterForm(true)}>Nemáš účet? Založ si ho zde.</button>
            </>
          )}
        </>
      )}

      {/* If logged in is true, render home page */}
      {loggedIn && <HomePage />}
    </div>
  );
}