// TODO: DOCUMENTATION + OPTIMIZATION
// *PACKAGES* //
import React from "react";
// *ASSETS* //
import logo from "../../assets/logo_transparent.png";
import copy from "../../assets/copy.png";

// Utility function to format the text
const formatText = (text) => {
  return text.length > 5 ? `${text.substring(0, 5)}...` : text;
};

export default function DataEntry ({ item, copyToClipboard }) {
  // Určení, zda existují data pro výsledek
  const hasResult = item.type === "Closed Long" || item.type === "Closed Short";

  return (
    <div className="dataEntry" data-result={item.result} data-type={item.type}>
      <div className="created_at">{item.created_at} UTC</div> {/* Přidáno vpravo nahoru */}
      <div className={`dataEntry_div1 ${hasResult ? "singleRow" : ""}`}>
        {item.type !== "Closed Long" && item.type !== "Closed Short" && (
          <>
            <h2 className="h2in">Entry:</h2>
            <button
              className="copyButton"
              onClick={() => copyToClipboard(item.entry)}
            >
              <h3 className="h3in">{formatText(item.entry)}</h3>
              <img
                className="copy"
                src={copy}
                alt="Copy"
              />
            </button>
            <h2 className="h2in">Take Profit:</h2>
            <button
              className="copyButton"
              onClick={() => copyToClipboard(item.profit)}
            >
              <h3 className="h3in">{formatText(item.profit)}</h3>
              <img
                className="copy"
                src={copy}
                alt="Copy"
              />
            </button>
            <h2 className="h2in">Stop Loss:</h2>
            <button
              className="copyButton"
              onClick={() => copyToClipboard(item.stop)}
            >
              <h3 className="h3in">{formatText(item.stop)}</h3>
              <img
                className="copy"
                src={copy}
                alt="Copy"
              />
            </button>
          </>
        )}
        {hasResult && (
          <>
            <h2 className="h2in">Result:</h2>
            <button className="buttonin">
              <h3 className="h3in">{item.result}</h3>
            </button>
            {/* Zde také můžeme přidat created_at, pokud je relevantní */}
          </>
        )}
      </div>
      <div className="dataEntry_div2">
        <h2 className="h2in">Coin:</h2>
        <button className="buttonin">
          <h3 className="h3in">{item.coin}</h3>
        </button>
        <h2 className="h2in">Type:</h2>
        <button className="buttonin">
          <h3 className="h3in">{item.type}</h3>
        </button>
      </div>
      <img src={logo} alt="logo" className="img_logo" />
    </div>
  );
};